.login-role-application__List {
  background: #F7F7F3;
  max-width: 450px; }
  @media (max-width: 768.98px) {
    .login-role-application__List {
      max-width: 80vw;
      max-height: 90%; } }
  @media (max-width: 479.98px) {
    .login-role-application__List {
      max-width: 75vw !important; } }
  .login-role-application__List #login-role-modal-content {
    width: 100%; }
    .login-role-application__List #login-role-modal-content .login-role-head_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px; }
      .login-role-application__List #login-role-modal-content .login-role-head_container #login-as__title {
        display: flex;
        align-items: center;
        justify-content: center; }
      .login-role-application__List #login-role-modal-content .login-role-head_container .H6DesktopBlack {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer; }
      .login-role-application__List #login-role-modal-content .login-role-head_container .cancel-icon {
        color: #979797; }
    .login-role-application__List #login-role-modal-content .login-role-applications {
      padding-left: 24px; }
      .login-role-application__List #login-role-modal-content .login-role-applications .account-switcher__account {
        margin-right: 0px !important;
        width: 100%; }

.submit-validation-modal-popup {
  max-width: 700px; }
  @media (max-width: 768.98px) {
    .submit-validation-modal-popup {
      max-width: 80vw;
      max-height: 70%; } }

.login-role-application__List .modal__inner #login-role-modal-content .login-role-head_container div .event-head {
  cursor: pointer; }
