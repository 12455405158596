.applied-application__List {
  background: #f6f6f6; }
  @media (max-width: 768.98px) {
    .applied-application__List {
      max-width: 80%;
      max-height: 60vh; } }
  .applied-application__List #applied-modal-content {
    width: 100%; }
    .applied-application__List #applied-modal-content .applied-head_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px; }
      .applied-application__List #applied-modal-content .applied-head_container .H6DesktopBlack {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer; }
      .applied-application__List #applied-modal-content .applied-head_container .apply-cancel_btn .cancel-icon {
        color: #979797;
        cursor: pointer; }
    .applied-application__List #applied-modal-content .applied-applications .apply-as-new {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 25px; }
    .applied-application__List #applied-modal-content .applied-applications .applicant-applications__my-application {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      margin-bottom: 30px; }
      @media (max-width: 1023.98px) {
        .applied-application__List #applied-modal-content .applied-applications .applicant-applications__my-application {
          grid-template-columns: repeat(1, 1fr); } }
    .applied-application__List #applied-modal-content .applied-footer-container {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .applied-application__List #applied-modal-content .applied-footer-container a {
        cursor: pointer; }
      .applied-application__List #applied-modal-content .applied-footer-container #apply-page__cta_close {
        padding: 8px 20px;
        border: none;
        margin-right: 10px; }
      .applied-application__List #applied-modal-content .applied-footer-container #apply-page__cta {
        padding: 8px 12px; }
