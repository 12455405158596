.login-role-application__List {
  background: #F7F7F3;
  max-width: 450px; }
  .login-role-application__List #login-error-modal-content {
    width: 100%; }
    .login-role-application__List #login-error-modal-content .login-error-head_container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px; }
      .login-role-application__List #login-error-modal-content .login-error-head_container #login-as__title {
        font-size: 24px; }
    .login-role-application__List #login-error-modal-content .content {
      padding-bottom: 20px;
      justify-content: center;
      align-items: center;
      display: flex; }
      .login-role-application__List #login-error-modal-content .content .action_body-mailto {
        color: #0000ee;
        text-decoration: underline; }
    .login-role-application__List #login-error-modal-content .action-footer {
      width: 100%;
      display: flex;
      justify-content: center; }
      .login-role-application__List #login-error-modal-content .action-footer button {
        padding: 8px 30px;
        font-size: 16px;
        height: fit-content; }
        .login-role-application__List #login-error-modal-content .action-footer button:focus-visible {
          outline: 2px solid #1E5558 !important;
          margin-right: 5px; }
      .login-role-application__List #login-error-modal-content .action-footer .cta-button {
        padding: 8px 20px; }
